import axios from '@/common/axios'
import qs from 'qs'

export function addUnqualifiedProducts (data) {
  return axios({
    method: 'post',
    url: '/quality/unqualifiedProducts/add',
    data: qs.stringify(data)
  })
}

export function deleteUnqualifiedProducts (id) {
  return axios({
    method: 'delete',
    url: '/quality/unqualifiedProducts/delete/' + id
  })
}

export function updateUnqualifiedProducts (data) {
  return axios({
    method: 'put',
    url: '/quality/unqualifiedProducts/update',
    data: qs.stringify(data)
  })
}

export function selectUnqualifiedProductsInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/unqualifiedProducts/info/' + id
  })
}

export function selectUnqualifiedProductsList (query) {
  return axios({
    method: 'get',
    url: '/quality/unqualifiedProducts/list',
    params: query
  })
}

export function reviewUnqualifiedProducts (data) {
  return axios({
    method: 'put',
    url: '/quality/unqualifiedProducts/updateReview',
    data: qs.stringify(data)
  })
}

export function approvalUnqualifiedProducts (data) {
  return axios({
    method: 'put',
    url: '/quality/unqualifiedProducts/updateReviewApproval',
    data: qs.stringify(data)
  })
}

export function generalUnqualifiedProducts (data) {
  return axios({
    method: 'put',
    url: '/quality/unqualifiedProducts/updateGeneralApprove',
    data: qs.stringify(data)
  })
}
