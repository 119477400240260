<template>
  <div id="unqualifiedProducts">
    <el-dialog
      :title="unqualifiedProductsFormTitle"
      width="680px"
      :visible.sync="unqualifiedProductsDialogVisible"
      :close-on-click-modal="false"
      @close="unqualifiedProductsDialogClose"
    >
      <el-form
        ref="unqualifiedProductsFormRef"
        :model="unqualifiedProductsForm"
        :rules="unqualifiedProductsFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="发生部门" prop="dept">
              <el-input
                v-model="unqualifiedProductsForm.dept"
                placeholder="请输入发生部门"
                clearable
                :disabled="unqualifiedProductsFormTitle !== '新增不合格品处理单'
                  && unqualifiedProductsFormTitle !== '修改不合格品处理单'
                  && unqualifiedProductsFormTitle !== '不合格品处理单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="productName">
              <el-input
                v-model="unqualifiedProductsForm.productName"
                placeholder="请输入名称"
                clearable
                :disabled="unqualifiedProductsFormTitle !== '新增不合格品处理单'
                  && unqualifiedProductsFormTitle !== '修改不合格品处理单'
                  && unqualifiedProductsFormTitle !== '不合格品处理单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格型号" prop="spec">
              <el-input
                v-model="unqualifiedProductsForm.spec"
                placeholder="请输入规格型号"
                clearable
                :disabled="unqualifiedProductsFormTitle !== '新增不合格品处理单'
                  && unqualifiedProductsFormTitle !== '修改不合格品处理单'
                  && unqualifiedProductsFormTitle !== '不合格品处理单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="unqualifiedProductsForm.batchNo"
                placeholder="请输入批号"
                clearable
                :disabled="unqualifiedProductsFormTitle !== '新增不合格品处理单'
                  && unqualifiedProductsFormTitle !== '修改不合格品处理单'
                  && unqualifiedProductsFormTitle !== '不合格品处理单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量" prop="quantity">
              <el-input
                v-model="unqualifiedProductsForm.quantity"
                placeholder="请输入数量"
                clearable
                :disabled="unqualifiedProductsFormTitle !== '新增不合格品处理单'
                  && unqualifiedProductsFormTitle !== '修改不合格品处理单'
                  && unqualifiedProductsFormTitle !== '不合格品处理单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发生日期" prop="occurDate">
              <el-date-picker
                v-model="unqualifiedProductsForm.occurDate"
                placeholder="请选择发生日期"
                value-format="yyyy-MM-dd"
                :disabled="unqualifiedProductsFormTitle !== '新增不合格品处理单'
                  && unqualifiedProductsFormTitle !== '修改不合格品处理单'
                  && unqualifiedProductsFormTitle !== '不合格品处理单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="不合格内容及初步原因分析" prop="unqualifiedContent">
              <el-input
                v-model="unqualifiedProductsForm.unqualifiedContent"
                placeholder="请输入不合格内容及初步原因分析"
                type="textarea"
                :rows="5"
                :disabled="unqualifiedProductsFormTitle !== '新增不合格品处理单'
                  && unqualifiedProductsFormTitle !== '修改不合格品处理单'
                  && unqualifiedProductsFormTitle !== '不合格品处理单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评审分析" prop="reviewAnalysis">
              <el-input
                v-model="unqualifiedProductsForm.reviewAnalysis"
                placeholder="请输入评审分析"
                type="textarea"
                :rows="5"
                :disabled="unqualifiedProductsFormTitle !== '不合格品处理单评审分析'
                  && unqualifiedProductsFormTitle !== '不合格品处理单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="处理意见（或措施）" prop="handlingOpinion">
              <el-input
                v-model="unqualifiedProductsForm.handlingOpinion"
                placeholder="请输入处理意见（或措施）"
                type="textarea"
                :rows="5"
                :disabled="unqualifiedProductsFormTitle !== '不合格品处理单处理意见（或措施）'
                  && unqualifiedProductsFormTitle !== '不合格品处理单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="总经理批准" prop="generalManagerOpinion">
              <el-radio-group
                v-model="unqualifiedProductsForm.generalManagerOpinion"
                :disabled="unqualifiedProductsFormTitle !== '不合格品处理单总经理批准'
                  && unqualifiedProductsFormTitle !== '不合格品处理单详情'"
              >
                <el-radio :label="1">
                  同意
                </el-radio>
                <el-radio :label="2">
                  不同意
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="unqualifiedProductsDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="unqualifiedProductsFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="批号">
        <el-input v-model="searchForm.batchNo" placeholder="请输入批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['UNQUALIFIED_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="unqualifiedProductsPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="dept" label="发生部门" />
      <el-table-column prop="productName" label="名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column label="发生日期">
        <template slot-scope="scope">
          <span v-if="scope.row.occurDate">{{ scope.row.occurDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="unqualifiedContent" label="不合格内容及初步原因分析" />
      <el-table-column prop="recorder" label="记录人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.recordDate">{{ scope.row.recordDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewAnalysis" label="评审分析" />
      <el-table-column prop="review" label="评审人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="handlingOpinion" label="处理意见（或措施）" />
      <el-table-column prop="reviewApprover" label="评审批准" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approveDate">{{ scope.row.approveDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总经理批准">
        <template slot-scope="scope">
          <span v-if="scope.row.generalManagerOpinion === 1">同意</span>
          <span v-if="scope.row.generalManagerOpinion === 2">不同意</span>
        </template>
      </el-table-column>
      <el-table-column prop="generalSignature" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.generalSignatureDate">{{ scope.row.generalSignatureDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['UNQUALIFIED_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['UNQUALIFIED_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['UNQUALIFIED_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            评审分析
          </el-button>
          <el-button
            v-if="checkPermission(['UNQUALIFIED_APPROVAL']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            处理意见（或措施）
          </el-button>
          <el-button
            v-if="checkPermission(['UNQUALIFIED_GENERAL']) && scope.row.status === 3"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleGeneral(scope.$index, scope.row)"
          >
            总经理批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="unqualifiedProductsPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addUnqualifiedProducts,
  deleteUnqualifiedProducts,
  updateUnqualifiedProducts,
  selectUnqualifiedProductsInfo,
  selectUnqualifiedProductsList,
  reviewUnqualifiedProducts,
  approvalUnqualifiedProducts,
  generalUnqualifiedProducts
} from '@/api/quality/unqualifiedProducts'

export default {
  data () {
    return {
      unqualifiedProductsDialogVisible: false,
      unqualifiedProductsFormTitle: '',
      unqualifiedProductsForm: {
        id: '',
        dept: '',
        productName: '',
        spec: '',
        batchNo: '',
        quantity: '',
        occurDate: '',
        unqualifiedContent: '',
        reviewAnalysis: '',
        handlingOpinion: '',
        generalManagerOpinion: '',
        status: '',
        taskId: ''
      },
      unqualifiedProductsFormRules: {
        batchNo: [{ required: true, message: '批号不能为空', trigger: ['blur', 'change']}]
      },
      unqualifiedProductsPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        batchNo: ''
      }
    }
  },
  created () {
    selectUnqualifiedProductsList(this.searchForm).then(res => {
      this.unqualifiedProductsPage = res
    })
  },
  methods: {
    unqualifiedProductsDialogClose () {
      this.$refs.unqualifiedProductsFormRef.resetFields()
    },
    unqualifiedProductsFormSubmit () {
      if (this.unqualifiedProductsFormTitle === '不合格品处理单详情') {
        this.unqualifiedProductsDialogVisible = false
        return
      }
      this.$refs.unqualifiedProductsFormRef.validate(async valid => {
        if (valid) {
          if (this.unqualifiedProductsFormTitle === '新增不合格品处理单') {
            this.unqualifiedProductsForm.id = ''
            this.unqualifiedProductsForm.status = 1
            await addUnqualifiedProducts(this.unqualifiedProductsForm)
          } else if (this.unqualifiedProductsFormTitle === '修改不合格品处理单') {
            await updateUnqualifiedProducts(this.unqualifiedProductsForm)
          } else if (this.unqualifiedProductsFormTitle === '不合格品处理单评审分析') {
            this.unqualifiedProductsForm.status = 2
            await reviewUnqualifiedProducts(this.unqualifiedProductsForm)
          } else if (this.unqualifiedProductsFormTitle === '不合格品处理单处理意见（或措施）') {
            this.unqualifiedProductsForm.status = 3
            await approvalUnqualifiedProducts(this.unqualifiedProductsForm)
          } else if (this.unqualifiedProductsFormTitle === '不合格品处理单总经理批准') {
            this.unqualifiedProductsForm.status = 4
            await generalUnqualifiedProducts(this.unqualifiedProductsForm)
          }
          this.unqualifiedProductsPage = await selectUnqualifiedProductsList(this.searchForm)
          this.unqualifiedProductsDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.unqualifiedProductsFormTitle = '新增不合格品处理单'
      this.unqualifiedProductsDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteUnqualifiedProducts(row.id)
        if (this.unqualifiedProductsPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.unqualifiedProductsPage = await selectUnqualifiedProductsList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.unqualifiedProductsFormTitle = '修改不合格品处理单'
      this.unqualifiedProductsDialogVisible = true
      this.selectUnqualifiedProductsInfoById(row.id)
    },
    handleReview (index, row) {
      this.unqualifiedProductsFormTitle = '不合格品处理单评审分析'
      this.unqualifiedProductsDialogVisible = true
      this.selectUnqualifiedProductsInfoById(row.id)
    },
    handleApproval (index, row) {
      this.unqualifiedProductsFormTitle = '不合格品处理单处理意见（或措施）'
      this.unqualifiedProductsDialogVisible = true
      this.selectUnqualifiedProductsInfoById(row.id)
    },
    handleGeneral (index, row) {
      this.unqualifiedProductsFormTitle = '不合格品处理单总经理批准'
      this.unqualifiedProductsDialogVisible = true
      this.selectUnqualifiedProductsInfoById(row.id)
    },
    handleInfo (index, row) {
      this.unqualifiedProductsFormTitle = '不合格品处理单详情'
      this.unqualifiedProductsDialogVisible = true
      this.selectUnqualifiedProductsInfoById(row.id)
    },
    selectUnqualifiedProductsInfoById (id) {
      selectUnqualifiedProductsInfo(id).then(res => {
        this.unqualifiedProductsForm.id = res.id
        this.unqualifiedProductsForm.dept = res.dept
        this.unqualifiedProductsForm.productName = res.productName
        this.unqualifiedProductsForm.spec = res.spec
        this.unqualifiedProductsForm.batchNo = res.batchNo
        this.unqualifiedProductsForm.quantity = res.quantity
        this.unqualifiedProductsForm.occurDate = res.occurDate
        this.unqualifiedProductsForm.unqualifiedContent = res.unqualifiedContent
        this.unqualifiedProductsForm.reviewAnalysis = res.reviewAnalysis
        this.unqualifiedProductsForm.handlingOpinion = res.handlingOpinion
        this.unqualifiedProductsForm.generalManagerOpinion = res.generalManagerOpinion
        this.unqualifiedProductsForm.status = res.status
        this.unqualifiedProductsForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectUnqualifiedProductsList(this.searchForm).then(res => {
        this.unqualifiedProductsPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectUnqualifiedProductsList(this.searchForm).then(res => {
        this.unqualifiedProductsPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectUnqualifiedProductsList(this.searchForm).then(res => {
        this.unqualifiedProductsPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['UNQUALIFIED_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['UNQUALIFIED_APPROVAL']) && row.status === 2) {
        return 'review'
      } else if (this.checkPermission(['UNQUALIFIED_GENERAL']) && row.status === 3) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
